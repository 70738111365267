;
(function($){

$('.menu-handle').on('click', function() {
    //console.log('lalalaal');
    $(this).find('i').toggleClass('arrow_carrot-left arrow_carrot-right');
    $(this).parents('.fixed-top').toggleClass('menu-closed');
    $('main').toggleClass('menu-closed');
});


$('.toggle-text .toggle-btn').on('click', function(e){
    showMoreLabel = $(this).parent().parent().find('.show-more-label') ? $(this).parent().parent().find('.show-more-label').html() : "Show more";
    showLessLabel = $(this).parent().parent().find('.show-less-label') ? $(this).parent().parent().find('.show-less-label').html() : "Show less";
    if($(this).parent().parent().find('.show-less').hasClass('d-none')){
       $(this).parent().parent().find('.show-less').removeClass('d-none');
       $(this).parent().parent().find('.show-all').addClass('d-none');
       $(this).html(showMoreLabel);
    }else{
       $(this).parent().parent().find('.show-all').removeClass('d-none');
       $(this).parent().parent().find('.show-less').addClass('d-none');
       $(this).html(showLessLabel);
    }
});

// form submit formations
$('div.formations-selector > a').on('click', function(e) {
    var form = $(this).parents('form#formations-selector:first');

    // check bb user is connected
    if ('pageUid' in $('#cloud-ui').data()) { return false; }
    
    // checkbox is checked?
    if (0 === form.serializeArray().length) { return false; }

    // submit
    form.submit();
    return false;
});

// form select/unselect all formations
$('form#formations-selector').find('th#selection').on('click', function(e) {
    var tableFormations = $(this).parents('table.table-formation:first');
    var checked = (tableFormations.find('input[name="formations[]"]:checked').length !== tableFormations.find('input[name="formations[]"]').length)
    ? true : false;
    tableFormations.find('input[name="formations[]"]').prop('checked', checked);

    return false;
});

})(jQuery);



